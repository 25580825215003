import React, { ElementType } from 'react'
import { ISbComponentType } from 'storyblok-js-client'
import styled, { CSSProperties } from 'styled-components'

import { DynamicBlock } from 'components/blocks/sections/DynamicBlock'
import { Text } from 'components/ui/deprecated/Text'
import { RichtextStoryblok, TextLinkStoryblok } from 'lib/storyblok/types'
import { cn } from 'lib/utils/tailwind'

import { addAlphaToColor, dashedLine } from '../../../lib/style'
import { CMSLink } from './CMSLink'

export type RendererFunction = (
  type: RichtextStoryblok['type'],
  renderDefault: () => JSX.Element
) => JSX.Element | null

type Props = {
  richtext?: RichtextStoryblok
  renderer?: RendererFunction
  className?: string
}

export type SubContentProps = {
  content: RichtextStoryblok
}

export const Paragraph = ({ content }: SubContentProps): JSX.Element => {
  const elements = content?.content?.reduce<JSX.Element[]>(
    (accumulator, current, index) => {
      const marks = current?.marks?.map((mark) => mark.type)

      const isLink = marks?.includes('link')
      const italic = marks?.includes('italic')
      const bold = marks?.includes('bold')
      const underline = marks?.includes('underline')
      const strike = marks?.includes('strike')
      const code = marks?.includes('code')

      const cssObj: CSSProperties = {
        fontStyle: italic ? 'italic' : 'inherit',
        fontWeight: bold ? 400 : 'inherit',
        textDecoration: `${underline ? 'underline' : ''} ${
          strike ? 'line-through' : ''
        }`,
      }

      if (current.type === 'image') {
        return [
          ...accumulator,
          <Img src={current.attrs?.src} alt={current.attrs?.alt} key={index} />,
        ]
      }

      if (current.type === 'hard_break') {
        return [...accumulator, <br key={index} />]
      }

      if (isLink) {
        const link = current.marks?.filter(({ type }) => type === 'link')[0]
          .attrs

        if (link) {
          /**
           * We have many editors who linked to external addresses without the protocol.
           * This hack adds https to all hrefs starting with www.
           */
          const linkHref = link.story
            ? link.story.full_slug
            : link.href?.startsWith('www.')
            ? `https://${link.href}`
            : link.href

          let href: TextLinkStoryblok['link'] = {
            linktype: 'url',
            cached_url: linkHref,
          }

          if (link.linktype && link.linktype === 'email') {
            href = {
              linktype: 'email',
              email: linkHref,
            }
          }

          if (link.linktype && link.linktype === 'story') {
            href = {
              linktype: 'story',
              cached_url: linkHref,
            }
          }

          cssObj.textDecoration = 'underline'

          return [
            ...accumulator,
            <CMSLink
              key={index}
              href={href}
              linkStyle="text"
              target={link.target || undefined}
            >
              <Text
                as="span"
                variant="twenty"
                color="accent.secondary"
                style={cssObj}
              >
                {current.text}
              </Text>
            </CMSLink>,
          ]
        }
        return [...accumulator]
      }

      if (code) {
        return [
          ...accumulator,
          <Code as="code" variant="twenty" style={cssObj} key={index}>
            {current.text}
          </Code>,
        ]
      }

      return [
        ...accumulator,
        <Text as="span" variant="twenty" style={cssObj} key={index}>
          {current.text}
        </Text>,
      ]
    },
    []
  )

  if (!elements?.length) return <></>

  return (
    <ParagraphText as="p" variant="twenty">
      {elements}
    </ParagraphText>
  )
}

const Heading = ({ content }: SubContentProps): JSX.Element => {
  const getVariant = () => {
    switch (content.attrs?.level) {
      case 2:
        return 'title/large'
      case 3:
        return 'title/small/regular'
      default:
        return 'title/small/regular'
    }
  }

  const elements = content?.content?.map((cont, index) => {
    return (
      <Text
        as="span"
        variant={getVariant()}
        key={index}
        className="pb-6 pt-10 first:pt-0"
      >
        {cont.text}
      </Text>
    )
  })

  if (!elements?.length || !content.attrs?.level) return <br />

  const tag = `h${content.attrs.level}` as ElementType

  return (
    <Text as={tag} variant="title/medium" className="pb-6 pt-10 first:pt-0">
      {elements}
    </Text>
  )
}

const getListItems = (content: RichtextStoryblok) => {
  const elements = content?.content?.map((cont, index) => {
    const innerElements = cont.content?.map((innerCont, index) => {
      return (
        <React.Fragment key={index}>{renderContent(innerCont)}</React.Fragment>
      )
    })

    return (
      <li key={index} className="relative" style={{ counterIncrement: 'item' }}>
        {innerElements}
      </li>
    )
  })

  return elements
}

const BulletList = ({ content }: SubContentProps): JSX.Element => {
  const elements = getListItems(content)

  if (!elements?.length) return <br />

  return (
    <ul
      className={cn(
        'relative ',
        '[&_ul]:mt-0 [&_ul]:mb-0 [&_ul]:pl-4',
        "[&>li]:flex-shrink-0 [&>li]:mb-3 [&>li]:ml-4 [&>li]:before:content-[''] [&>li]:before:size-1 [&>li]:before:align-middle [&>li]:before:mr-[0.4em] [&>li]:before:inline-block [&>li]:before:absolute [&>li]:before:top-3 [&>li]:before:-left-4",
        '[&>li]:before:bg-foreground [&>li]:before:rounded-full'
      )}
    >
      {elements}
    </ul>
  )
}

const OrderedList = ({ content }: SubContentProps): JSX.Element => {
  const elements = getListItems(content)
  const start = content.attrs?.order || 1

  if (!elements?.length) return <br />

  return (
    <Ol start={start} className="list-decimal pl-4">
      {elements}
    </Ol>
  )
}

const CodeBlock = (): null => null

const BlockQuote = ({ content }: SubContentProps): JSX.Element => {
  return (
    <QuoteWrapper>
      {content?.content?.map((cont, index) => {
        return (
          <React.Fragment key={index}>{renderContent(cont)}</React.Fragment>
        )
      })}
    </QuoteWrapper>
  )
}

export const Blok = ({ content }: SubContentProps): JSX.Element => {
  return (
    <>
      {content.attrs?.body?.map(
        (body: Array<ISbComponentType<string>>, index: number) => {
          return <DynamicBlock block={body} key={index} />
        }
      )}
    </>
  )
}

const renderContent = (
  content: RichtextStoryblok,
  renderer: RendererFunction = (_, renderDefault) => renderDefault()
) => {
  switch (content.type) {
    case 'paragraph':
      return renderer('paragraph', () => <Paragraph content={content} />)
    case 'heading':
      return renderer('heading', () => <Heading content={content} />)
    case 'bullet_list':
      return renderer('bullet_list', () => <BulletList content={content} />)
    case 'ordered_list':
      return renderer('ordered_list', () => <OrderedList content={content} />)
    case 'horizontal_rule':
      return renderer('horizontal_rule', () => <Hr />)
    case 'code_block':
      return renderer('code_block', () => <CodeBlock />)
    case 'blockquote':
      return renderer('blockquote', () => <BlockQuote content={content} />)
    case 'blok':
      return renderer('blok', () => <Blok content={content} />)
    default:
      throw new Error(`Received unexpected content type: ${content.type}`)
  }
}

export const CMSRichText = ({
  richtext,
  renderer,
  className,
  ...props
}: Props): JSX.Element => {
  if (!richtext?.content) return <></>
  return (
    <div {...props} className={className}>
      {richtext?.content.map((content, index) => {
        return (
          <React.Fragment key={index}>
            {renderContent(content, renderer)}
          </React.Fragment>
        )
      })}
    </div>
  )
}

const ParagraphText = styled(Text)`
  padding-bottom: 20px;

  & ~ & {
    padding-bottom: 20px;
  }
`

const QuoteWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.625rem;

  margin: 2.5rem 0;
  padding: 2rem;

  & > h1,
  h2,
  h3,
  h4 {
    padding: 0;
  }

  & > p {
    max-width: 510px;
    padding: 0;
  }

  border-radius: 1rem;
  background-color: ${({ theme }) =>
    addAlphaToColor(theme.colors.foreground.default, 5)};
`

const Code = styled(Text)`
  background: ${({ theme }) => theme.colors.background.default};
  border: 1px ${({ theme }) => theme.colors.foreground.default} solid;
  border-radius: 0.25rem;
  padding: 0.25rem 0.5rem;
  white-space: pre;
`

const Hr = styled.hr`
  border-color: transparent;
  ${({ theme }) => dashedLine('bottom', theme.colors.foreground.default)};
`

const Ol = styled.ol`
  margin: 1rem 0;
`

const Img = styled.img`
  margin: 1rem 0;
  border-radius: 1rem;
`
